$navbarHeader: white;
$background: white;

$lightBackground: white;
$darkBackground: black;

:export {
  navbarHeader: $navbarHeader;
  background: $background;
  lightBackground: $lightBackground;
  darkBackground: $darkBackground;
}

$lightHover: gray;
$darkHover: gray;

$lightBody: black;
$darkBody: white;
$lightLink: black;
$darkLink: white;

$spacer: 8px;

$headerZIndex: 10;
