@import "../src/consts/consts.scss";

body {
  background-color: $lightBackground;
  color: $lightBody;

  a {
    color: $lightLink;

    &:hover {
      color: $lightHover;
    }
  }
}

.dark {
  background-color: $darkBackground;
  color: $darkBody;

  transition: background-color 0.25s ease;

  a {
    color: $darkLink;

    &:hover {
      color: $darkHover;
    }
  }
}
