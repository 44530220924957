// @import "@/consts/consts.scss";
@import "../../consts/consts.scss";

.header {
  position: sticky;
  top: 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacer * 4;
  margin: auto;
  padding: $spacer * 4;
  width: 100%;
  max-width: 1000px;

  .intro {
    display: flex;
    flex-direction: row;
    gap: $spacer * 2;
    width: 100%;

    &__profile-pic {
      margin: auto;
      height: 300px;
      border-radius: 25px;
    }
  }

  .resume {
    text-align: center;

    &__link {
      text-decoration: none;
      border: 1px solid;
      border-radius: 4px;
      padding: 8px;
    }
  }
}
