@import "../../../src/consts/consts.scss";

.Experience {
  display: flex;
  flex-direction: column;

  .work {
    display: flex;
    flex-direction: column;
    gap: $spacer * 4;

    &__experience-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $spacer * 4;
      justify-content: left;
    }
  }
}
