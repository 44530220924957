@import "../../src/consts/consts.scss";

.header {
  background-color: $navbarHeader;
  display: flex;
  height: 100%;
  position: sticky;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: $headerZIndex;

  &.dark {
    background-color: black;
    color: white;

    * > a {
      color: white;
    }

    .header__title {
      color: white;
    }
  }

  * > a {
    text-decoration: none;
    border-radius: 4px;
    padding: 8px;
  }

  .header__title {
    padding: 0px 2 * $spacer;
    font-size: 48px;
    margin: 0;

    &--theme-toggle {
      background: transparent;
      border: transparent;
      position: absolute;
      right: 2 * $spacer;
    }
  }

  .header__links {
    display: flex;
    flex-direction: row;
    gap: $spacer;
    font-size: x-large;
    align-items: center;

    padding: 0px 2 * $spacer;
  }
}
