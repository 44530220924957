@import "../consts/consts.scss";

.WorkExperienceCard {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $spacer * 2;
  border: 1px solid gray;
  border-radius: 10px;
  width: 400px;

  &__header {
    position: relative;
    display: flex;
    gap: $spacer * 2;
    align-items: center;
    font-size: x-large;

    .company-logo {
      max-width: 44px;
      max-height: 44px;
    }
  }

  &__content {
    margin-top: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $spacer;

    .date {
      font-size: small;
      color: gray;
    }

    .description {
      font-size: small;
    }
  }
}
